import {
    useCallback, useRef, useState,
} from "react";
import {
    Button,
    Form, Table,
} from "react-bootstrap";

import STATE_CHANGE_TYPE from "../../services/STATE_CHANGE_TYPE";
import {LEFT_PANEL_TAB_IDS} from "../LeftPanel/LeftPanel";
import DetailsPanelHeader from "./DetailsPanelHeader";

import "./DetailsPanel.scss";


/**
 * Renders a details panel for viewing properties of current log object.
 *
 * @param props
 * @param props.logEventDetails
 * @param props.setLeftPanelActiveTabId
 * @param props.handleSearchQueryChange
 * @param props.handleStateChange
 * @param props.defaultPatternString
 */
const DetailsPanel = ({
    logEventDetails,
    setLeftPanelActiveTabId,
    handleSearchQueryChange,
    handleStateChange,
    defaultPatternString,
}) => {
    const [patternString, setPatternString] = useState<string>(defaultPatternString ??
        "%d{yyyy-MM-dd HH:mm:ss.SSS} [%process.thread.name] %log.level %message%n");
    const [cursorPosition, setCursorPosition] = useState(0);
    const patternStringInputRef = useRef(null);

    const handlePatternStringSelect = useCallback((ev) => {
        setCursorPosition(ev.target.selectionStart);
    }, []);

    const handlePatternStringChange = (ev) => {
        setPatternString(ev.target.value);
    };

    const handlePatternStringSubmit = (ev) => {
        ev.preventDefault();
        handleStateChange(STATE_CHANGE_TYPE.PATTERN_STRING, {
            patternString: patternString,
        });
    };

    const handleKeyClick = (value: string) => {
        setPatternString(
            (v) => `${v.substring(0, cursorPosition)}%${value}${v.substring(cursorPosition, v.length)}`
        );
        patternStringInputRef.current.focus();
    };

    const handleValueClick = (value) => {
        handleSearchQueryChange({
            isRegex: false,
            matchCase: true,
            searchString: value.toString(),
        });
        setLeftPanelActiveTabId(LEFT_PANEL_TAB_IDS.SEARCH);
    };

    return (
        <>
            <div style={{padding: "0 15px", overflowX: "auto", height: "100%"}}>
                <DetailsPanelHeader/>
                <Form onSubmit={handlePatternStringSubmit}>
                    <div
                        style={{
                            display: "flex",
                            marginBottom: "6px",
                            marginTop: "6px",
                            justifyItems: "center",
                        }}
                    >
                        <Form.Label style={{flexGrow: 1}}>Pattern String</Form.Label>
                        <Button
                            size={"sm"}
                            type={"submit"}
                            variant={"primary"}
                        >
                            Reload
                        </Button>
                    </div>
                    <Form.Control
                        as={"textarea"}
                        ref={patternStringInputRef}
                        value={patternString}
                        onChange={handlePatternStringChange}
                        onSelect={handlePatternStringSelect}/>
                </Form>
                <hr/>

                <Table
                    bordered={true}
                    hover={true}
                    striped={true}
                    style={{fontSize: "0.9rem"}}
                >
                    <thead>
                        <tr>
                            <th>Key</th>
                            <th>Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        {null !== logEventDetails &&
                        Object.entries(logEventDetails).map(([key, value]) => (
                            <tr key={key}>
                                <td>
                                    <a
                                        href={""}
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                            handleKeyClick(key);
                                        }}
                                    >
                                        <b>
                                            {key}
                                        </b>
                                    </a>
                                </td>
                                <td>
                                    <a
                                        href={""}
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                            handleValueClick(value);
                                        }}
                                    >
                                        {value}
                                    </a>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        </>
    );
};

export default DetailsPanel;
