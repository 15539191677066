let DOWNLOAD_WORKER_ACTION = {
    initialize: "initialize",
    pageData: "pageData",
    progress: "progress",
    clearDatabase: "clearDatabase",
    error: "error",
};
DOWNLOAD_WORKER_ACTION = Object.freeze(DOWNLOAD_WORKER_ACTION);

export default DOWNLOAD_WORKER_ACTION;
