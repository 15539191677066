/**
 *
 */
const DetailsPanelHeader = ({
}) => (
    <div className={"tab-details-header"}>
        <div className={"tab-search-header-text"}>DETAILS</div>
    </div>
);

export default DetailsPanelHeader;
